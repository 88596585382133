var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"text-center",attrs:{"fluid":""}},[_c('Loader',{attrs:{"loading":_vm.loading}}),_c('Table-Container',{attrs:{"title":_vm.$route.name,"exportInfo":_vm.currItems,"dates":[_vm.forms.begin, _vm.forms.end]},scopedSlots:_vm._u([{key:"bottom-header",fn:function(){return [_c('v-row',{staticClass:"mx-4"},[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('Date-Picker',{attrs:{"label":"Fecha inicio","offYear":"","offLimit":""},model:{value:(_vm.forms.begin),callback:function ($$v) {_vm.$set(_vm.forms, "begin", $$v)},expression:"forms.begin"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('Date-Picker',{attrs:{"label":"Fecha fin","offYear":"","offLimit":""},model:{value:(_vm.forms.end),callback:function ($$v) {_vm.$set(_vm.forms, "end", $$v)},expression:"forms.end"}})],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","md":"1"}},[_c('Basic-Btn',{attrs:{"text":"Buscar","color":"success","icon":"mdi-magnify","loading":_vm.loading,"disabled":!(_vm.forms.begin && _vm.forms.end)},on:{"click":function($event){return _vm.refresh()}}})],1)],1)]},proxy:true},{key:"search",fn:function(){return [_c('v-text-field',{staticClass:"mx-2",attrs:{"append-icon":"mdi-magnify","label":"Buscar","single-line":"","hide-details":"","maxlength":"150"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true}])},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"search":_vm.search,"footer-props":{ 'items-per-page-text': 'Elementos por página', 'items-per-page-options': [20, 50, -1] },"items-per-page":_vm.itemsPerPage,"item-key":"no_empleado","single-expand":"","show-expand":"","dense":""},on:{"current-items":_vm.getFiltered},scopedSlots:_vm._u([{key:"item.nombre_empleado",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.nombre_empleado + " " + item.apellido_paterno + " " + item.apellido_materno)+" ")]}},{key:"item.tiempo_retardo",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.tiempo_retardo ? item.tiempo_retardo : "")+" ")]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('br'),_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_c('strong',[_vm._v("Fecha")])]),_c('v-col',{attrs:{"cols":"2"}},[_c('strong',[_vm._v("Entrada")])]),_c('v-col',{attrs:{"cols":"2"}},[_c('strong',[_vm._v("Inicio comida")])]),_c('v-col',{attrs:{"cols":"2"}},[_c('strong',[_vm._v("Fin comida")])]),_c('v-col',{attrs:{"cols":"2"}},[_c('strong',[_vm._v("Salida")])]),_c('v-col',{attrs:{"cols":"1"}},[_c('strong',[_vm._v("Minutos")])]),_c('v-col',{attrs:{"cols":"1"}},[_c('strong',[_vm._v("Utilizado")])])],1),_vm._l((item.detalle),function(val,date){return [(val)?_c('v-row',{key:date},[_c('v-col',{attrs:{"cols":"2"}},[_c('strong',[_vm._v(_vm._s(date))])]),_c('v-col',{attrs:{"cols":"2"}},[_vm._v(" "+_vm._s(item.fechas[date].hora_entrada)+" ")]),_c('v-col',{attrs:{"cols":"2"}},[_vm._v(" "+_vm._s(item.fechas[date].hora_inicio_comida)+" ")]),_c('v-col',{attrs:{"cols":"2"}},[_vm._v(" "+_vm._s(item.fechas[date].hora_fin_comida)+" ")]),_c('v-col',{attrs:{"cols":"2"}},[_vm._v(" "+_vm._s(item.fechas[date].hora_salida)+" ")]),_c('v-col',{attrs:{"cols":"1"}},[_vm._v(" "+_vm._s(val)+" ")]),_c('v-col',{attrs:{"cols":"1"}},[_vm._v(" "+_vm._s(item.detalle_consumo[date])+" ")])],1):_vm._e()]}),_c('br')],2)]}},{key:"footer.page-text",fn:function(props){return [_vm._v(" Página "+_vm._s(props.pageStart)+" - "+_vm._s(props.pageStop)+" de "+_vm._s(props.itemsLength)+" ")]}},{key:"no-data",fn:function(){return [_vm._v(" No hay información disponible ")]},proxy:true},{key:"no-results",fn:function(){return [_vm._v(" No se obtuvieron resultados ")]},proxy:true}],null,true)},[_c('v-progress-linear',{attrs:{"slot":"progress","color":"secondary","indeterminate":""},slot:"progress"})],1)],1),_c('BasicAlert',{attrs:{"active":_vm.alert.active,"msg":_vm.alert.msg,"color":_vm.alert.color},on:{"update:active":function($event){return _vm.$set(_vm.alert, "active", $event)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }